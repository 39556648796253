import { Modal } from "@/components/Modal/Modal";
import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { Link, NextImage, useI18n } from "@jog/react-components";
import React from "react";
const countryCodeToName = {
    BE: "BELGIUM",
    DE: "GERMANY",
    NL: "THE NETHERLANDS",
    US: "GLOBAL",
};
const getStoreURLByCountryCode = (countryCode) => {
    switch (countryCode) {
        case "DE":
            return "https://www.wearegarcia.de/";
        case "NL":
            return "https://www.wearegarcia.nl/";
        case "BE":
            return "https://www.wearegarcia.be/";
        default:
            return "https://www.wearegarcia.com/";
    }
};
const CountryRedirectModal = ({ hideModal, userCountryCode }) => {
    const { $t } = useI18n();
    const country = process.env.NEXT_PUBLIC_COUNTRY || "";
    const suggestedCountryCode = ["DE", "NL", "BE"].includes(userCountryCode) ? userCountryCode : "US";
    return (<Modal hideModal={hideModal} height="auto" modalClass="!min-w-[90%] lg:!min-w-[300px] !px-2 lg:px-5">
            <div className="flex flex-col content-center items-center py-0 lg:px-3.75">
                {country === "US" ? (<>
                        <h2 className="mb-2 p-1 font-primary text-sm font-bold uppercase lg:mb-5 lg:text-26">
                            {$t("Select country")}
                        </h2>
                        <div className="grid w-full grid-cols-1 grid-rows-3 gap-3.75 lg:grid-cols-3">
                            {[
                { name: "Germany - Deutsch", url: "https://www.wearegarcia.de", countryCode: "DE" },
                { name: "Netherlands - Dutch", url: "https://www.wearegarcia.nl", countryCode: "NL" },
                { name: "Belgium - Dutch", url: "https://www.wearegarcia.be", countryCode: "BE" },
                { name: "Luxembourg - English", url: "https://www.wearegarcia.com", countryCode: "LU" },
                { name: "Spain - English", url: "https://www.wearegarcia.com", countryCode: "ES" },
                { name: "Austria - English", url: "https://www.wearegarcia.com", countryCode: "AT" },
                { name: "France - English", url: "https://www.wearegarcia.com", countryCode: "FR" },
            ].map((country, index) => (<div key={index}>
                                    <a className="relative inline-block w-full break-words pl-4 text-13 text-black no-underline hover:no-underline" href={country.url} onClick={(e) => {
                    if (country.url === "https://www.wearegarcia.com") {
                        e.preventDefault();
                        e.stopPropagation();
                        hideModal();
                    }
                }}>
                                        <NextImage className="absolute top-0 left-0 inline-block h-4 w-auto" blur={false} width={27} height={38} src="/images/black-bag.png" alt="country"/>
                                        {country.name}
                                    </a>
                                </div>))}
                        </div>
                        <p className="mt-3 text-center text-sm leading-tight lg:mt-8.75">
                            {$t("Please select your country and preferred language. When your country is not on the list, it is unfortunately not possible to place an order.")}
                        </p>
                    </>) : (<>
                        <h2 className="mb-5 pt-1 font-primary text-sm font-bold uppercase lg:text-26">{`${$t(`NOT IN`)} ${$t(countryCodeToName[country])}?`}</h2>
                        <p className="m-0 text-center text-xs lg:text-sm">
                            {$t("You are about to buy products in another country. Please note that the purchase will not be available.")}
                        </p>
                        <p className="m-0 text-center text-sm">
                            {$t("We have a webshop in your country that you might prefer to shop instead.")}
                        </p>
                        <Link className="mt-2 lg:mt-7.5" href={getStoreURLByCountryCode(suggestedCountryCode)}>
                            <PrimaryButton>
                                {$t("CONTINUE")} {$t(countryCodeToName[suggestedCountryCode])}
                            </PrimaryButton>
                        </Link>
                    </>)}
            </div>
        </Modal>);
};
export default React.memo(CountryRedirectModal);
