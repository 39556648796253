// TODO delete this file: react-bootstrap   10px = 10px
export const theme = {
    color: {
        sandGrey: "#939393", //grey-sand
        lightSandGrey: "#E8E3D7", //grey-light
        beigeGrey: "#f6f4f0", //grey-beige
        checkoutGrey: "#F9F8F5", //grey-checkout
        shuttleGray: "#5C616B", //gray-shuttle
        darkGray: "#707070",
        gray: "#a1a4aa",
        silverGray: "#bfbfbf",
        mediumGray: "#d0d2d4",
        lightGray: "#eeeeee",
        backgroundGray: "#f7f7f7",
        draculaGray: "#212529",
        monzaRed: "#E30613",
        mainRed: "#EF0F0F",
        errorRed: "#a54445",
        pink: "#ea5175",
        salmonPink: "#f1dede",
        darkBlue: "#153e6e",
        taraBlue: "#074054",
        whiteBlue: "#2196f3",
        yellow: "#ffcf0c",
        lightYellow: "#fcf8e3",
        mcKenzie: "#8a6d3b",
        primaryHover: "auto",
        white: "#ffffff",
        black: "#000000",
        lightOrange: "#ef9e77",
        alertBoxGray: "#434955",
    },
    zIndex: {
        fixed: 7,
        headerWrapper: 2,
        navWrapper: 7,
        snackbar: 3,
        dropDownBox: 4,
        modal: 9,
        logoLoader: 10,
        brandLogo: 2,
    },
    padding: {
        large: "20px",
        standard: "10px",
        small: "5px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        pd1: "3px",
        pd2: "5px",
        pd3: "10px",
        pd4: "15px",
        pd5: "20px",
        pd6: "30px",
    },
    spacing: {
        // Note: we don't have a Design System yet so this is arbitrary
        sp1: "30px",
        sp2: "5px",
        sp3: "10px",
        sp4: "15px",
        sp5: "20px",
        sp6: "30px",
    },
    fontFamily: {
        primary: "Untitled Sans",
        icomoonJC: "icomoon-jc",
    },
    fontSize: {
        paragraph: "8px",
        label: "11px",
        itemLabel: "11px", // BUG: This was missing from the JS land, this is a value created to make TSC happy
        mainHeader: "20px",
        secondaryHeader: "9px",
        itemHeader: "15px",
        priceElement: "16px",
        modalParagraph: "14px",
        // Progressive enhancement - start using these on new code
        // Note: we don't have a Design System yet so this is arbitrary
        fs1: "8px",
        fs2: "9px",
        fs3: "11px",
        fs4: "14px",
        fs5: "15px",
        fs6: "16px",
        fs7: "20px",
    },
    // This property is also used in util/responsiveImages.js
    media: {
        sm: "screen and (max-width: 575px)", //   sm =    0 -  575
        md: "screen and (max-width: 767px)", //   md =  576 -  767
        lg: "screen and (max-width: 991px)", //   lg =  768 -  991
        xl: "screen and (max-width: 1199px)", //   xl =  992 - 1199
        xxl: "screen and (max-width: 1399px)", //  xxl = 1200 - 1399
        // xxxl: 'screen and (max-width: inf); // xxxl = 1400 -  inf - illustrative only, use 'theme.mediaFrom.xxl' to target this size
    },
    // This follows Bootstrap breakpoints
    mediaFrom: {
        // xs: 'screen and (min-width: 0px)'   //  xs =    0 -  575 - illustrative only, use default styles (no media queries) to target this size
        sm: "screen and (min-width: 576px)", //  sm =  576 -  767 -- ** Unused **
        md: "screen and (min-width: 768px)", //  md =  768 -  991 -- ** TABLET **
        lg: "screen and (min-width: 992px)", //  lg =  992 - 1199 -- ** DESKTOP **
        xl: "screen and (min-width: 1200px)", //  xl = 1200 - 1399 -- ** Unused **
        xxl: "screen and (min-width: 1400px)", // xxl = 1400 -  inf -- ** Unused **
    },
};
